// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';



// Home layout


// Dark HomeLayout 
import DarkPortfolioLanding from './main/Landing/PortfolioLanding';
import DarkMainDemo from './main/Portfolio/MainDemo';

// Element Layout
import PageScrollTop from './unused/component/PageScrollTop';

// Blocks Layout
import Gallery from "./main/Portfolio/Gallery";

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ProjectDetail from './main/ProjectDetail';
import * as serviceWorker from './serviceWorker';
import Contact from './unused/elements/Contact';

class Root extends Component{
    render(){
        if (window.location.host.split(".")[0] == "fitze") {
            return(
                <BrowserRouter basename={'/'}>
                    <Switch>
                     <Route exact path={`/`} component={() => { 
                            window.location.href = 'https://apps.apple.com/id/app/fitze/id6444094864'; 
                            return null;
                    }}/>
                    <Redirect to={"/"} />
                    </Switch>
                </BrowserRouter>
            )
           
        } else if (window.location.host.split(".")[0] == "fitzeseller") {
            return(
                <BrowserRouter basename={'/'}>
                    <Switch>
                     <Route exact path={`/`} component={() => { 
                            window.location.href = 'https://testflight.apple.com/join/TCeIzQm7'; 
                            return null;
                    }}/>
                    <Redirect to={"/"} />
                    </Switch>
                </BrowserRouter>
            )
           
        } else if (window.location.host.split(".")[0] == "easy") {
            return(
                <BrowserRouter basename={'/'}>
                    <Switch>
                     <Route exact path={`/`} component={() => { 
                            window.location.href = 'https://apps.apple.com/id/app/easy-nail-your-essay/id1633606208'; 
                            return null;
                    }}/>
                    <Redirect to={"/"} />
                    </Switch>
                </BrowserRouter>
            )
           
        } else {
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkPortfolioLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Gallery}/>
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={DarkMainDemo}/> */}
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details/:name`} component={DarkMainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/projects/:name`} component={ProjectDetail}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={Business}/> */}
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/demo`} component={Demo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-landing`} component={PortfolioLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`} component={DarkPortfolioLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/designer-portfolio`} component={DesignerPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-portfolio`} component={CreativePortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/interior-landing`} component={InteriorLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/corporate-business`} component={CorporateBusiness}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/interactive-agency`} component={InteractiveAgency}/>

                        {/* Element Layot */}
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
                       
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/> */}

                        {/* Blocks Elements  */}
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}/> */}


                        
                        {/* <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/> */}
                        {/* <Route component={<Redirect to={"/"} />}/> */}
                        <Redirect to={"/"} />
                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )}
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();