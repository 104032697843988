import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor, FiSmartphone, FiCamera, FiEdit, FiPercent, FiStar, FiPenTool } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiSmartphone />,
        title: 'Mobile App Development',
        description: `Expertise: Cross-Platform & iOS Development`
    },
    {
        icon: <FiLayers />,
        title: 'Website Development',
        description: 'Expertise: Front-End Development'
    },
    {
        icon: <FiUsers />,
        title: 'UI/UX Design',
        description: 'Several experience in creating design for website or mobile app'
    },
    {
        icon: <FiPenTool />,
        title: 'Product Management',
        description: 'The value is in what gets used, not what gets built - Kris Gale'
    },
    {
        icon: <FiStar />,
        title: 'Collaborative Approach & Hard Work',
        description: 'Alone we can do so little, together we can do so much more!'
    },
    {
        icon: <FiPercent />,
        title: 'Full Commitment',
        description: '100% is what I always give to the project'
    },
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
