import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp, FiLayers, FiMonitor, FiSmartphone, FiUsers } from "react-icons/fi";
import Header from "../unused/component/header/Header";

import Helmet from "../unused/component/common/Helmet";
import ContactThree from "./ContactThree";
import FooterTwo from "../unused/component/footer/FooterTwo";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from 'react-router';
import { prototype } from "google-map-react";
import ModalVideo from "react-modal-video";
import ProjectInfo from "../unused/component/slider/ProjectInfo";
import nontech from "./Portfolio/portfoliolistnontech";
import Error404 from "../unused/elements/error404";



const ProjectDetail = () => {

        const {name} = useParams()

        const [portfolio, setPortfolio] = useState()
        const [isOpen, setIsOpen] = useState(false)

        useEffect(() => {
            console.log(name)
            setPortfolio(nontech.filter(list => list.name === name)[0])
            
        },[]);
        useEffect(() => {
            console.log(name)
            console.log(portfolio)
            // setPortfolio(portfoliolist.filter(list => list.name === this.props.match.params.name)[0])
        },[portfolio]);

        
        return(
            portfolio !== undefined ? (

            
            <div className="active-dark"> 
                <Helmet pageTitle={`${portfolio.name} - Porfolio Breakdown`} />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="symbol-dark" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <ProjectInfo title={portfolio.displayName} list={portfolio.ServiceList} type={portfolio.type} />
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About</h2>
                                        <p className="description">{portfolio.description}</p>
                                    </div>
                                    <div className="mt--30">
                                        <h4>What did I do?</h4>
                                        <ul className="list-style--1">
                                            {portfolio.whatIDo.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name.title}</li>;
                                            })}
                                        </ul>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src={`/assets/images/portfolio/${portfolio.image}`} alt="About Images"/>
                                    {
                                        portfolio.videoId !== '' ? <ModalVideo channel='youtube' isOpen={isOpen} videoId={portfolio.videoId} onClose={() => {setIsOpen(false)}} /> : <div></div>
                                    }
                                    {
                                        portfolio.videoId !== '' ? <button className="video-popup position-top-center theme-color" onClick={() => {setIsOpen(true)}}><span className="play-icon"></span></button> : <div></div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                {/* <div className="service-area ptb--80  bg_image bg_image--3">
                   <div className="container">
                        <ServiceTwo techStack = {portfolio.techStack} />
                   </div>
                </div> */}
                {/* End Service Area  */}

              
                {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/about/about-9ss.png" contactTitle="Hire Me." />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
                
            </div>
            ) : <div><Error404 /></div>
        )
}
export default ProjectDetail;