import { FiAlertCircle, FiAnchor, FiLayers, FiLayout, FiMonitor, FiSmartphone, FiUser, FiUsers, FiWatch } from "react-icons/fi";
import React, { Component } from "react";
import { FaCamera, FaGlasses, FaReact, FaSafari, FaUsers, FaVrCardboard } from "react-icons/fa";
import { prototype } from "google-map-react";


export const nontech = [
    {
        name : 'cornerstalk',
        displayName: 'The Corners Talk',
        image: 'tct.png',
        description: "The Corners Talk is a podcast series produced in collaboration with Harian Kompas. It aims to present a combination between up-to-date topic and inspirational source person. I pioneered the making of this podcast as head of production for the first season and built the foundation for following seasons. #WeTalkToInspire", 
        whatIDo : [
            {
                title: 'Led the production process',
            },
            {
                title: 'Researched and built the foundation of the podcast with the team (editing guideline, branding, etc)',
            },
            {
                title: 'Coordinatde with Harian Kompas about potential source person or event collaboration',
            },
            {
                title: 'Evaluated and made improvements based on podcast performance',
            },
            {
                title: 'Produced 14 episodes in the span of 3 months',
            },
            {
                title: 'Collaborated with known entity such as masker untuk indonesia',
            },
           
        ],
        ServiceList : [
            {
               
                title: 'Roles',
                description: 'Head of Production'
            },
            {
               
                title: 'Platform and Development',
                description: 'Podcast'
            },
            {
               
                title: '',
                description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
            },
        ],
        type : {
            type: 'podcast',
            link: ''
        },
        videoId: 'lA8n4tbYTWU'
    }, 
    {
        name : 'skystar',
        displayName: 'Skystar Ventures Journey',
        image: 'skystarjourney.png',
        description: "Skystar Ventures is a tech incubator and coworking space founded by Universitas Multimedia Nusantara (UMN) and Kompas Gramedia Group (KGG). I joined as part of Batch 7 Incubator Program with Game4Change, educational startup that aims to make learning process more fun", 
        whatIDo : [
            {
                title: 'Learned about business validation, MVP, and market validation',
            },
            {
                title: 'Received mentorship and handled day-to-day operations',
            },
            {
                title: 'Involved in marketing campaign for Wilah, educational card game to teach kids how to separate waste',
            },
            {
                title: 'Represented Skystar Ventures in Kompetisi Bisnis Mahasiswa Indonesia (KBMI) by Indonesia ministry of cultural and education',
            },
            {
                title: 'Selected as winner of KBMI',
            }
        ],
        ServiceList : [
            {
               
                title: 'Roles',
                description: 'Incubation Participant'
            },
            {
               
                title: 'Platform and Development',
                description: 'Business, Self-Development'
            },
            {
               
                title: '',
                description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
            },
        ],
        type : {
            type: 'incubation',
            link: ''
        },
        videoId: ''
    }, 
   
    
]
export default nontech