import React, { Component } from "react";
import { FiCast } from "react-icons/fi";





class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {this.props.list.map( (val , i) => val.title != '' ? (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                {/* <div className="icon">
                                    {val.icon} */}
                                    {/* <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/> */}
                                {/* </div> */}
                                <div className="content">
                                    
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12" key={i}>
                        <div></div>
                        <div className="service service__style--1">
                            <div className="content">
                                <a href={this.props.type.link} target="_blank">
                                    <div className="icon">
                                    {this.props.type.type == 'appstore' ? (
                                        
                                        <img className="h-100" src="/assets/images/about/appStorelight.png" alt="About Images"/>
                                    ) : this.props.type.type == 'testflight' ? (
                                        <img className="" src="/assets/images/about/testflight.png" alt="About Images"/>
                                    ) :  this.props.type.type == 'google' ? (
                                        <img className="" src="/assets/images/about/googlePlay.png" alt="About Images"/>
                                    ) : <div></div>}
                                    </div>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;