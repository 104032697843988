import React, { Component } from 'react'
import PageHelmet from "../../unused/component/common/Helmet";
import Breadcrumb from "./Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../unused/component/header/Header";
import Footer from "../../unused/component/footer/FooterTwo";
import portfoliolist   from './portfoliolist';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import BrandTwo from '../../unused/elements/BrandTwo';
import nontech from './portfoliolistnontech';
import HeaderThree from '../HeaderThree';


const TabOne = [
    {
        image: '01',
        bigImage: 'tct.png',
        category: 'Web Design',
        title: 'The Corners Talk'
    },
    {
        image: '02',
        bigImage: 'skystarjourney.png',
        category: 'Mobile App',
        title: 'SkyStar Ventures Incubation Program'
    },
   
]

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab1, isOpen } = this.state;
        return (
            <div className='active-dark'>

            <PageHelmet pageTitle='Portfolio' />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
            {/* Start Header Area  */}
            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Gallery'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper active-dark">
                {/* Start Portfolio Area  */}
                <div className="creative-portfolio-wrapper ptb--120 bg_color--1">
                    <div className="container plr--10">
                        <div className="row row--5">
                            {portfoliolist.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="portfolio-style--3">
                                        <div className="thumbnail">
                                            <a href="/portfolio-details">
                                                <img className="w-100 h-100" src={`/assets/images/about/${value.image}`} alt="Portfolio Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            {/* <p className="portfoliotype">{value.category}</p> */}
                                            <h4 className="title"><a href="/portfolio-details">{value.displayName}</a></h4>
                                            <div className="portfolio-btn">
                                                <a className="rn-btn text-white" href={`/portfolio-details/${value.name}`}>View Details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */} 

                <div className="rn-brand-area brand-separation">
                        <div className="container">
                        <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Featured Works</h2>
                                        <p>With tons of stories and learnings</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-12">
                                    <CompanyList />
                                    <BrandTwo />
                                </div>
                            </div> */}
                        </div>
                    </div>

                     {/* Start Portfolio Area  */}
                 <div className="rn-portfolio-area bg_color--1 active-dark">
                    <div className="container">
                        <div className="row">
                            {nontech.map((value , index) => (
                                <div className="col-lg-6" key={index}>
                                    
                                    <div className="item-portfolio-static">
                                        <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <a href={`/projects/${value.name}`}>
                                                            <img src={`/assets/images/portfolio/${value.image}`} alt="Portfolio Images"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                       
                                                        <h4>{value.displayName}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}                
            </div>
        )
    }
}


export default Gallery;