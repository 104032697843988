import React, { Component } from "react";
import {Link} from "react-router-dom";
import portfoliolist from "../Portfolio/portfoliolist";

const PortfolioListContent = [
    {
        image: 'image-6',
        category: 'iOS, Programmatic UI, AR',
        title: 'Fitze',
        link: 'fitze',
        linkTItle: 'Coming Soon'
    },
    {
        image: 'image-4',
        category: 'iOS, UI/UX, Mobile Dev, SwiftUI',
        title: 'refo',
        link: 'refo',
        linkTItle: 'View Details'
    },
    {
        image: 'image-1',
        category: 'iOS, Mobile Dev',
        title: 'Panion',
        link: 'panion',
        linkTItle: 'Try it'
    },
    {
        image: 'image-2',
        category: 'iOS, UI/UX, Mobile Dev',
        title: 'Easy (Easy Essay)',
        link: 'easy',
        linkTItle: 'Try it'
    },
    {
        image: 'image-3',
        category: 'Cross-Platform, UI/UX, Mobile Dev',
        title: 'bjb DigiLoan',
        link: 'digiloan',
        linkTItle: 'Try it'
    },
    {
        image: 'image-5',
        category: 'Web-Apps, React',
        title: 'bjb Account Officer Website',
        link: 'accountofficer',
        linkTItle: 'Try it'
    },
    
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = portfoliolist.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.name}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={`/portfolio-details/${value.name}`}>{value.displayName}</a></h4>
                                    <div className="portfolio-button">
                                         <a className="rn-btn" href={`/portfolio-details/${value.name}`}>View Details</a>  
                                    </div>
                                </div>
                            </div>
                            {/* <Link className="link-overlay" to="/portfolio-details"></Link> */}
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;