import React, { Component } from 'react';
import Header from "../component/header/Header";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Helmet } from 'react-helmet';
import FooterTwo from '../component/footer/FooterTwo';

 class Error404 extends Component {
    
    render() {
        return (
            
            <div className="active-dark"> 
                <Helmet pageTitle={`Error 404`} />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="symbol-dark" />

                {/* Start About Area */}
                <div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                        <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title theme-gradient">404!</h1>
                                    <h3 className="sub-title">Whoops!</h3>
                                    <span>The page you were looking for could not be found.</span>
                                    <div className="error-button">
                                        <a className="rn-button-style--2 btn-solid" href="/">Back To Homepage</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
                
            </div>
        )
    }
}
export default Error404;
