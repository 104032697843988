import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab2 = "Work Experience",
        tab23 = "Organizational Experience",
        tab3 = "Education";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>                                    
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab23}</Tab>
                                        <Tab>{tab3}</Tab>
                                       
                                    </TabList>

                                    {/* <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">User experience design <span> - UI/UX</span></a>
                                                    Delight the user and make it work.
                                                </li>
                                                <li>
                                                    <a href="/service">Web and user interface design<span> - Development</span></a>
                                                    Websites, web experiences, ...
                                                </li>
                                                <li>
                                                    <a href="/service">Interaction design <span> - Animation</span></a>
                                                    I like to move it move it.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel> */}

                                    {/* <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Awwwards.com <span>- Winner</span></a> 2019 - 2020
                                               </li>
                                               <li>
                                                   <a href="/service">CSS Design Awards <span>- Winner</span></a> 2017 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Design nominees <span>- site of the day</span></a> 2013- 2014
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel> */}



                                    <TabPanel>
                                       <div className="single-tab-content brand-style-2">
                                           <ul>
                                               <li>
                                                  <a>iOS Engineer Intern<span> - Nusantara Beta Studio </span></a> February 2023 - June 2023
                                               </li>
                                               
                                               <li>
                                                 <a>iOS Engineer<span> - Apple Developer Academy </span></a> March 2022 - December 2022
                                               </li>
                                               <li>
                                                   <a>Software Engineer Intern<span> - Bank BJB</span></a> January 2022 - May 2022
                                               </li> 
                                           </ul>

                                           
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a>Project Manager<span> - Kompas Corner (Under Harian Kompas)</span></a> August 2019 - December 2020
                                               </li>
                                               
                                               <li>
                                                   <a>Incubator Participant<span> - Skystar Ventures </span></a> April 2020 - October 2020
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Learners<span> - Apple Developer Academy@BINUS</span></a> March 2022 - December 2022
                                               </li>
                                               <li>
                                                   <a href="/service">Computer Science Major<span> - Multimedia Nusantara University</span></a> 2019 - 2023
                                               </li>
                                              
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;