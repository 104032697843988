import { FiAlertCircle, FiAnchor, FiLayers, FiLayout, FiMonitor, FiSmartphone, FiUser, FiUsers, FiWatch } from "react-icons/fi";
import React, { Component } from "react";
import { FaCamera, FaGlasses, FaReact, FaSafari, FaUsers, FaVrCardboard } from "react-icons/fa";
import { prototype } from "google-map-react";


export const portfoliolist = [
    {
        name : 'fitze',
        displayName: 'Fitze',
        image: 'fitze.png',
        description: "Fitze helps you to see enhanced product catalog, discover a unique shopping experience with our 3D Model preview that provide 360 plain overview of the product, Augmented Reality and Size Calculator in one single app.",
        techStack : [
            {
                icon: <FiSmartphone />,
                title: 'Programmatic UI (UIKit)',
                description: 'Using industry standard Front-End Development for better readability, modularity, and maintainability'
            },
            {
                icon: <FiLayout />,
                title: 'SnapKit',
                description: 'For simple layoutting process'
            },
            {
                icon: <FiLayers />,
                title: 'MVVM Architecture',
                description: 'For better collaborative working, code reusability, and ease of testing'
            },
            {
                icon: <FiUsers />,
                title: 'Firebase',
                description: 'Store, Authenticate, and dynamic links to make everything cloud-based'
            },
            {
                icon: <FiAnchor />,
                title: 'SceneKit (3D Model Display)',
                description: 'Giving user 360 degrees overview of fashion products'
            },
            {
                icon: <FaVrCardboard />,
                title: 'Augmented Reality (using ARKit)',
                description: 'Display 3D models to real world environment'
            }
        ],
        ServiceList : [
            {
               
                title: 'Roles',
                description: 'Technical Lead, iOS Developer, Research Team'
            },
            {
               
                title: 'Platform and Development',
                description: 'iOS, Swift'
            },
            {
               
                title: '',
                description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
            },
        ],
        type : {
            type: 'appstore',
            link: 'https://apps.apple.com/id/app/fitze/id6444094864'
        }
    },
    {
        name : 'easy',
        displayName: 'easy',
        image: 'easy.jpg',
        description: "Easy helps you to draft and outline your future essays by outlining your ideas and drafting the introduction, body, and conclusion of your essay.", 
        techStack : [
            {
                icon: <FiSmartphone />,
                title: 'UIKit',
                description: 'Implementation of multiple TableView and Controller Lifecycle'
            },
            {
                icon: <FiLayers />,
                title: 'MVC Architecture',
                description: 'Separating Model, View, and Controller for better structure and faster development process'
            },
            {
                icon: <FaSafari />,
                title: 'Safari Services',
                description: 'Embeed browser right in the application so users can easily access their links'
            },
            {
                icon: <FiUsers />,
                title: 'Core Data',
                description: 'Easily save data in user phone'
            }
        ],
        ServiceList : [
            {
               
                title: 'Roles',
                description: 'iOS Developer, UI/UX Designer'
            },
            {
               
                title: 'Platform and Development',
                description: 'iOS, watchOS, SwiftUI'
            },
            {
               
                title: '',
                description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
            },
        ],
        type : {
            type: 'appstore',
            link: 'https://apps.apple.com/id/app/easy-nail-your-essay/id1633606208'
        }
    },
    {
        name : 'panion',
        displayName: 'Panion',
        image: 'about-1.jpg',
        description: "Panion is a companion app for people dealing with panic attacks, to help them regain control of their 5 human senses. ",
        techStack : [
            {
                icon: <FiSmartphone />,
                title: 'UIKit',
                description: 'Using storyboard, including the making of drag and drop and preview context using collectionView'
            },
            {
                icon: <FiLayers />,
                title: 'MVC Architecture',
                description: 'Separating Model, View, and Controller for better structure and faster development process'
            },
            {
                icon: <FiUsers />,
                title: 'User Defaults',
                description: 'Simple information storing system to enhance user preference and customization process'
            },
            {
                icon: <FiAlertCircle />,
                title: 'Haptic Engine (using CHHaptic)',
                description: 'Accessing Haptic engine to give calming vibration right from the phone'
            }
        ],
        ServiceList : [
            {
               
                title: 'Roles',
                description: 'Technical Lead, iOS Developer, Research Team'
            },
            {
               
                title: 'Platform and Development',
                description: 'iOS, Swift, Storyboard'
            },
            {
               
                title: '',
                description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
            },
        ],
        type : {
            type: 'appstore',
            link: 'https://apps.apple.com/id/app/panion-panic-companion/id1628000219'
        }
    },
    {
        name : 'accountofficer',
        displayName: 'BJB Account Officer Website',
        image: 'webao.jpg',
        description: "Website to help Account Officer manage credit data and make approval / rejection", 
        techStack : [
            {
                icon: <FaReact />,
                title: 'React',
                description: 'Build modern Web Apps Interface and large community base'
            },
            {
                icon: <FiUsers />,
                title: 'Redux',
                description: 'Manage async process and data loading for efficient process and great readability, also to improve cookie storing for authentication process'
            },
           
        ],
        ServiceList : [
            {
               
                title: 'Roles',
                description: 'Front-End Developer, UI/UX Designer'
            },
            {
               
                title: 'Platform and Development',
                description: 'Website'
            },
            {
               
                title: '',
                description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
            },
        ],
        type : {
            type: 'web',
            link: ''
        }
    }, 
    // {
    //     name : 'refo',
    //     displayName: 'Refo',
    //     image: 'refo.png',
    //     description: "Refo helps you to increase your productivity by managing various learning resources and managing focus timer.",
    //     techStack : [
    //         {
    //             icon: <FiSmartphone />,
    //             title: 'SwiftUI',
    //             description: 'Build apps in two platform with one single code base'
    //         },
    //         {
    //             icon: <FiLayers />,
    //             title: 'MVVM Architecture',
    //             description: 'For better collaborative working, code reusability, and ease of testing'
    //         },
    //         {
    //             icon: <FaGlasses />,
    //             title: 'Optical Character Recognition (using VisionKit)',
    //             description: 'Help scan images for code resources and turn it into text so user can easily copy it'
    //         },
    //         {
    //             icon: <FiUsers />,
    //             title: 'Core Data',
    //             description: 'Easily save data in user phone'
    //         }
    //     ],
    //     ServiceList : [
    //         {
               
    //             title: 'Roles',
    //             description: 'iOS Developer, UI/UX Designer'
    //         },
    //         {
               
    //             title: 'Platform and Development',
    //             description: 'iOS, watchOS, SwiftUI'
    //         },
    //         {
               
    //             title: '',
    //             description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
    //         },
    //     ],
    //     type : {
    //         type: 'testflight',
    //         link: 'https://testflight.apple.com/join/MhZ5ONup'
    //     }
    // },
    {
        name : 'digiloan',
        displayName: 'bjb digiloan',
        image: 'digiloan.jpg',
        description: "bjb DigiLoan helps people to apply for bank bjb's credit products via personal cell phones so that they can be more flexible and can track the application process", 
        techStack : [
            {
                icon: <FiSmartphone />,
                title: 'Flutter',
                description: 'Easily build apps for both iOS and Android'
            },
            {
                icon: <FiLayers />,
                title: 'UI Design',
                description: 'Redesign previous UI Design based on product requirements and business process'
            },
            {
                icon: <FaCamera />,
                title: 'Camera Accessibility',
                description: 'Access native features such as camera so user can change their profile picture'
            },
           
        ],
        ServiceList : [
            {
               
                title: 'Roles',
                description: 'UI/UX Designer, Front-End Developer'
            },
            {
               
                title: 'Platform and Development',
                description: 'iOS, Android'
            },
            {
               
                title: '',
                description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
            },
        ],
        type : {
            type: 'appstore',
            link: 'https://apps.apple.com/id/app/bjb-digiloan/id1619283005?l=id'
        }
    },
    {
        name : 'uevents',
        displayName: 'UEvents',
        image: 'uevents.png',
        description: "UEvents help you to develop the newest event and recruitment venue at UMN and register yourself immediately. Location, date, description, everything you want to know about the latest event, you can find on UEvents.", 
        techStack : [
            {
                icon: <FiSmartphone />,
                title: 'Android Native',
                description: 'Implementation of recycle view and view lifecycle in Java'
            },
            {
                icon: <FiUsers />,
                title: 'Firebase',
                description: 'Authenticate and store everything in cloud-based databse that able me to focus on learning Android front-end development'
            }
        ],
        ServiceList : [
            {
               
                title: 'Roles',
                description: 'Front-End Developer'
            },
            {
               
                title: 'Platform and Development',
                description: 'Android, Java'
            },
            {
               
                title: '',
                description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
            },
        ],
        type : {
            type: 'google',
            link: 'https://play.google.com/store/apps/details?id=uevents.ac.id&pli=1'
        }
    },
]
export default portfoliolist